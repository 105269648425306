import React, { ChangeEvent, useRef, useState } from 'react';
import './styles/index.css';
import { RavenButton, RavenInputField, toast } from '@ravenpay/raven-bank-ui';
import uploadPlaceholder from '../../../../../assets/images/upload-logo-placeholder.png';
import { icons } from '../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { bankboxAPI } from '../../../../../redux/bankbox';
import RootState, { ActivateBankBoxPayload } from '../../../../../redux/types';
import {
	calculateDarkerColor,
	validHex,
} from '../../../../../utils/colorConvert';
import { validateEmail } from '../../../../../utils/helper/ValidateInput';
import { shadeColor } from '../../../../../utils/colorConvert/darkenLightenColor';
import { updateBusinessInfo } from '../../../../../redux/kyc';
import {
	getCookie,
	replaceSpacesWithDash,
	setCookie,
} from '../../../../../utils/helper/Helper';
import Colorful from '@uiw/react-color-colorful';
import { nigerianStates } from '../../data/statesInNigeria';
import {
	cleanHex,
	getContrastingColor,
	getInverseColor,
} from '../../settings/components/colorConvert';
import { FaCheck } from 'react-icons/fa';
import { getProfile } from '../../../../../redux/settings';

const SetupBankbox = ({
	onComplete,
	step,
	setStep,
	onChange,
	formData,
	setBrandColor,
}: {
	onComplete: () => void;
	onChange: (e: any) => any;
	setBrandColor: (e: any) => any;
	setStep: (e: { id: string; completed: string[]; label: string }) => void;
	step: {
		id: string;
		completed: string[];
		label: string;
	};
	formData: ActivateBankBoxPayload;
}) => {
	const fileInputRef = useRef<HTMLInputElement>(null);
	const imgPreviewRef = useRef<HTMLImageElement>(null);
	const [isSwatchOpen, setIsSwatchOpen] = React.useState(false);
	const [logo, setLogo] = useState<File | undefined>();
	const { profile } = useSelector((state: RootState) => state.settings);
	const [color, setColor] = useState('#F7F8F7');

	const handleUploadClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	function cleanseString(input: string) {
		// Use a regular expression to remove non-digit characters
		return input.replace(/[^\d]/g, '');
	}

	const { loading } = useSelector((state: RootState) => state.bankbox);

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		setLogo(file);
		if (file && imgPreviewRef.current) {
			const reader = new FileReader();

			reader.onload = (e) => {
				if (imgPreviewRef.current) {
					imgPreviewRef.current.src = e.target?.result as string;
				}
			};

			reader.readAsDataURL(file);
		}
	};

	const brandColors = ['0B8376', '755AE2', 'EA872D', 'B9DEFE'];

	const [canNavigate, setCanNavigate] = useState(['basic']);

	const dispatch = useDispatch();
	const handleUpload = () => {
		const uploadData = new FormData();

		uploadData.append('business_logo', logo as Blob);
		dispatch(bankboxAPI.uploadLogo(uploadData));
	};
	const [loadTest, setLoadTest] = useState(false);
	async function handleActivate() {
		// console.log({
		//   ...formData,
		//   device_price: cleanseString(formData.device_price),
		//   cap_amount: cleanseString(formData.cap_amount),
		// });
		// return;
		setLoadTest(true);
		const resp = await dispatch(
			bankboxAPI.activateBankbox({
				...formData,
				app_name: replaceSpacesWithDash(formData?.app_name),
				device_price: cleanseString(formData.device_price),
				cap_amount: cleanseString(formData.cap_amount),
				brand_color_code: cleanHex(formData.brand_color_code),
			})
		);
		// console.log(resp, '------------check 1 -------------');

		handleUpload();
		if (resp?.payload?.status === 'success') {
			// console.log(resp, '--------------check 2 ---------------');
			setLoadTest(false);
			dispatch(getProfile());
			await setCookie('x-pos-key', resp.payload.data.data.appkey, 3000);
			// window.location.reload();
			// onComplete();
			// console.log(resp.payload.data.data.appkey, '-----key---');

			const resp2 = await dispatch(
				bankboxAPI.setBankboxKey({
					bankbox_key: resp.payload.data.data.appkey,
				})
			);
			onComplete();
			window.location.reload();
			if (resp2?.payload?.status === 'success') {
				toast.success('Bankbox account successfully activated');
				onComplete();
			}
		} else {
			setLoadTest(false);
		}
	}

	function isValidHttpUrl(string: string) {
		let url;

		try {
			url = new URL(string);
		} catch (_) {
			return false;
		}

		return (
			url.protocol === 'http:' ||
			url.protocol === 'https:' ||
			url.protocol === 'www'
		);
	}
	const [showEmailError, setShowEmailError] = useState(false);
	function disableAlgorithm() {
		if (
			step.id === 'basic' &&
			(formData.app_name.length < 2 ||
				!validHex(formData.brand_color_code) ||
				!logo)
		) {
			return true;
		} else if (
			step.id === 'price' &&
			((formData.device_price as unknown as number) < 500 ||
				!formData.cap_fee_percentage ||
				!formData.cap_amount)
		) {
			return true;
		} else if (
			step.id === 'support' &&
			(!formData.suppport_email ||
				!isValidHttpUrl(formData.website) ||
				!formData.phone ||
				!formData.operating_region ||
				!formData.address)
		) {
			return true;
		} else if (
			step.id === 'webhook' &&
			(!isValidHttpUrl(formData.webhook_url) || !formData.webhook_secret)
		) {
			return true;
		} else return false;
	}

	return (
		<div
			// onClick={() => {
			// 	console.log(profile);
			// }}
			className="bankbox-modals"
		>
			<div className="bankbox-modals__left ">
				<div className="bankbox-modals__left--bg-absolute"></div>
				<div style={{ zIndex: 100 }} className="bankbox-modals__left--stepper">
					<div
						onClick={() =>
							canNavigate.includes('basic') &&
							setStep({
								id: 'basic',
								label: 'Basic Details',
								completed: [''],
							})
						}
						style={{ cursor: 'pointer' }}
						className={`stepper-item ${step.id === 'basic' && 'active'} ${
							step.completed.includes('basic') && 'completed'
						}`}
					>
						<span>
							<p>1</p>
						</span>
						<h6>Basic Details</h6>
					</div>

					<div
						onClick={() =>
							canNavigate.includes('price') &&
							setStep({
								id: 'price',
								label: 'Price Rate & Fees',
								completed: ['basic'],
							})
						}
						style={{ cursor: 'pointer' }}
						className={`stepper-item ${step.id === 'price' && 'active'} ${
							step.completed.includes('price') && 'completed'
						}`}
					>
						<span>
							<p>2</p>
						</span>
						<h6>Price Rate & Fees</h6>
					</div>

					<div
						onClick={() => {
							canNavigate.includes('support') &&
								setStep({
									id: 'support',
									label: 'Support & Operations',
									completed: ['basic', 'price'],
								});
						}}
						className={`stepper-item ${step.id === 'support' && 'active'} ${
							step.completed.includes('support') && 'completed'
						}`}
					>
						<span>
							<p>3</p>
						</span>
						<h6>Support & Operation</h6>
					</div>

					<div
						onClick={() =>
							canNavigate.includes('webhook') &&
							setStep({
								id: 'webhook',
								label: 'Webhook & Keys',
								completed: ['basic', 'price', 'support'],
							})
						}
						className={`stepper-item ${step.id === 'webhook' && 'active'} ${
							step.completed.includes('webhook') && 'completed'
						}`}
					>
						<span>
							<p>4</p>
						</span>
						<h6>Webhook Details</h6>
					</div>
				</div>
			</div>

			<div className="bankbox-modals__right">
				{step.id === 'basic' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Basic Settings</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="basic-content__business-logo">
							<figure onClick={handleUploadClick}>
								<input
									ref={fileInputRef}
									type="file"
									accept="image/*"
									style={{ display: 'none' }}
									onChange={(e) => {
										Number(e.target.files?.length) > 0 && handleFileChange(e);
									}}
								/>
								<img
									ref={imgPreviewRef}
									src={
										logo ? URL.createObjectURL(logo as any) : uploadPlaceholder
									}
									alt="preview"
								/>
							</figure>
							<p>Click on the icon to upload business logo *</p>
						</div>

						<div className="basic-content__input-contain">
							<RavenInputField
								name="app_name"
								onChange={onChange}
								value={replaceSpacesWithDash(formData.app_name)}
								placeholder="e.g Hubert"
								label="App Name *"
								color="deep-green-light"
							/>

							<div className="basic-content__input-contain--brand-color">
								<span className="selector-title">
									<p>Select a Brand Color</p>
								</span>

								<div
									style={{ position: 'relative' }}
									className="color-selector"
								>
									{brandColors.map((chi, i) => {
										const darkerColor = shadeColor(`#${chi}`, 1.6);

										return (
											<span
												key={i}
												onClick={() => setBrandColor(chi)}
												className={`bColors ${
													formData.brand_color_code === chi ? 'active' : ''
												}`}
												style={{
													background: `#${chi}`,
													borderColor: darkerColor,
												}}
											>
												{formData.brand_color_code === chi && (
													<FaCheck style={{ color: '#ffffff' }} />
												)}
											</span>
										);
									})}
									<span
										onClick={() => setIsSwatchOpen(!isSwatchOpen)}
										className="bColors swatch-indicator"
										style={{ background: color }}
									>
										<figure>
											{
												<svg
													width="11"
													height="7"
													viewBox="0 0 11 7"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M1.20117 1.5L5.20117 5.5L9.20117 1.5"
														stroke={getContrastingColor(color)}
														strokeWidth="2.0152"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											}
										</figure>
									</span>

									{isSwatchOpen && (
										<div
											style={{
												position: 'fixed',
												top: '0',
												height: '100%',
												width: '100%',
											}}
											onClick={() => setIsSwatchOpen(false)}
											className="open-swatch-overlay"
										></div>
									)}
									<div
										style={{ right: '0' }}
										className={`more-wrap ${
											isSwatchOpen && 'show-drop swatch-drop'
										}`}
									>
										<div className="colour-pallete">
											<Colorful
												color={formData.brand_color_code}
												disableAlpha={true}
												onChange={(color: { hexa: string }) => {
													setColor(color.hexa);
													onChange({
														target: {
															name: 'brand_color_code',
															value: color.hexa,
														},
													});
													// dispatch(setBrandColor(color.hexa));
												}}
											/>

											<div className="color-input">
												<RavenInputField
													value={formData.brand_color_code}
													onChange={onChange}
													name="brand_color_code"
													color="deep-green-light"
													label="Colour Code *"
												/>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="basic-content__form-submit">
								<RavenButton
									onClick={() => {
										setStep({
											id: 'price',
											label: 'Price Rate & Fees',
											completed: ['basic'],
										});
										setCanNavigate([...canNavigate, 'price']);
									}}
									disabled={disableAlgorithm()}
									color="deep-green-light"
									label="Continue"
								/>
							</div>
						</div>
					</div>
				)}

				{step.id === 'price' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Basic Settings</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="device_price"
								onChange={onChange}
								value={formData.device_price}
								placeholder="e.g ₦22,000.00"
								label="Device Price (₦) *"
								color="deep-green-light"
								thousandFormat
								type={`number`}
								numberPrefix={`₦`}
							/>

							<div className="price-content__input-contain--grouped">
								<RavenInputField
									name="cap_fee_percentage"
									// onChange={onChange}
									onChange={(e: { target: { value: any } }) => {
										Number(e.target.value) <= 100 && onChange(e);
									}}
									value={formData.cap_fee_percentage}
									placeholder="e.g 0.5%"
									label="Rate %  *"
									color="deep-green-light"
								/>
								<RavenInputField
									name="cap_amount"
									placeholder="e.g ₦500.00"
									onChange={onChange}
									value={formData.cap_amount}
									label="Amount Capped (₦)  *"
									color="deep-green-light"
									thousandFormat
									type={`number`}
									numberPrefix={`₦`}
								/>
							</div>
						</div>
						<div className="price-content__form-submit">
							<RavenButton
								onClick={() => {
									setStep({
										id: 'support',
										label: 'Support & Operations',
										completed: ['basic', 'price'],
									});
									setCanNavigate([...canNavigate, 'support']);
								}}
								disabled={disableAlgorithm()}
								color="deep-green-light"
								label="Continue"
							/>{' '}
						</div>
					</div>
				)}

				{step.id === 'support' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Basic Settings</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="suppport_email"
								placeholder="Enter Email Address"
								onChange={(e: any) => {
									showEmailError && setShowEmailError(false);
									onChange(e);
								}}
								labelSpanText={
									(
										<>
											<p
												className="grey-bg"
												style={{
													padding: '.8rem 1.4rem',
													borderRadius: '3rem',
												}}
											>
												<span style={{ fontSize: '1.2rem', fontWeight: '500' }}>
													Use my email
												</span>
											</p>
										</>
									) as any
								}
								onActionClick={() => {
									const obj = {
										target: {
											name: 'suppport_email',
											value: profile?.email,
										},
									};
									onChange(obj);
								}}
								value={formData.suppport_email}
								label="Support email Address  *"
								color="deep-green-light"
								type="email"
								showError={showEmailError}
							/>
							<RavenInputField
								name="website"
								placeholder="https://www.fbt***.com"
								onChange={onChange}
								value={formData.website}
								label="Website *  "
								showError={
									formData.website?.length > 3 &&
									!isValidHttpUrl(formData.website)
								}
								errorText="A valid url must contain https or http"
								color="deep-green-light"
							/>
							<RavenInputField
								name="phone"
								type="phone"
								placeholder="Enter Phone Number"
								onChange={onChange}
								value={formData.phone}
								label="Phone * "
								color="deep-green-light"
								labelSpanText={
									(
										<>
											<p
												className="grey-bg"
												style={{
													padding: '.8rem 1.4rem',
													borderRadius: '3rem',
												}}
											>
												<span style={{ fontSize: '1.2rem', fontWeight: '500' }}>
													Use my phone number
												</span>
											</p>
										</>
									) as any
								}
								onActionClick={() => {
									const obj = {
										target: {
											name: 'phone',
											value: profile?.merchantInfo?.phone_number,
										},
									};
									onChange(obj);
								}}
							/>
							<RavenInputField
								name="operating_region"
								placeholder="e.g 'Abia State'"
								label="Region of Operation *"
								value={{
									value: formData.operating_region,
									label: formData.operating_region,
								}}
								type="select"
								onChange={(e: { label: any }) => {
									onChange({
										target: {
											name: 'operating_region',
											value: e.label,
										},
									});
								}}
								selectOption={nigerianStates}
								style={{ zIndex: '4400' }}
								color="deep-green-light"
							/>
							<RavenInputField
								name="address"
								placeholder="Where does your business operate"
								label="Operating Address *"
								onChange={onChange}
								value={formData.address}
								type="text"
								color="deep-green-light"
							/>
						</div>
						<div className="price-content__form-submit" >
							<RavenButton
								loading={loading}
								loaderText="Activating..."
								disabled={disableAlgorithm()}
								onClick={() => {
									if (!validateEmail(formData?.suppport_email)) {
										toast.error('Invalid Support Email Address');
										setShowEmailError(true);
										return;
									} else {
										setStep({
											id: 'webhook',
											label: 'Webhook Details',
											completed: ['basic', 'price', 'support'],
										});
										setCanNavigate([...canNavigate, 'webhook']);
									}
								}}
								color="deep-green-light"
								label="Continue"
							/>{' '}
						</div>
					</div>
				)}

				{step.id === 'webhook' && (
					<div className="bankbox-modals__right--content">
						<div className="basic-content__title">
							<h5>Basic Settings</h5>
							<h6>{step.label}</h6>
						</div>

						<div className="price-content__input-contain">
							<RavenInputField
								name="webhook_url"
								placeholder="Enter Webhook URL"
								type="text"
								onChange={onChange}
								showError={
									formData.webhook_url?.length > 3 &&
									(!isValidHttpUrl(formData.webhook_url) as boolean)
								}
								errorText="URL must start with http or https"
								value={formData?.webhook_url}
								label="Webhook URL  "
								color="deep-green-light"
							/>
							<RavenInputField
								name="webhook_secret"
								placeholder="Webhook Secret"
								onChange={onChange}
								value={formData?.webhook_secret}
								label="Webhook Secret   "
								color="deep-green-light"
							/>
						</div>
						<div className="price-content__form-submit" >
							<RavenButton
								loading={loading || loadTest}
								loaderText="Activating..."
								onClick={() => {
									// setStep({
									// 	id: 'basic',
									// 	label: 'Basic Setting',
									// 	completed: [''],
									// });
									handleActivate();
								}}
								// disabled={disableAlgorithm()}
								color="deep-green-light"
								label="Complete Setup "
							/>{' '}
						</div>
					</div>
				)}
				{/* <div className="height-box"></div> */}
			</div>
		</div>
	);
};

export default SetupBankbox;
