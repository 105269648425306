import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../../layout/dashboard/dashboardLayout';
import './styles/index.css';
import {
	getActionNamesByPolicyName,
	removeCommaAndNairaSign,
	searchParams,
	trimLongString,
} from '../../../../utils/helper/Helper';

import { useDarkMode } from '../../../../hooks/useDarkMode';

import { useDispatch, useSelector } from 'react-redux';
// import { getSettingsConfig } from '../../../../redux/settings';
import { AppDispatch } from '../../../../redux/store';
// import { TeamFormData } from '../../settings/components/team';
import BasicProfile from './components/BasicProfile';
import { bankboxAPI } from '../../../../redux/bankbox';
import RootState, { BankboxMerchantProfile } from '../../../../redux/types';
import { RavenButton, toast } from '@ravenpay/raven-bank-ui';
import useScrollToLocation from '../../../../hooks/useScrolltoLocation';
import { cleanHex } from './components/colorConvert';
import EODExportModal from '../../../../components/common/exportModal/EODExportModal';
import DeleteModal from '../../../../components/common/deleteModal/DeleteModal';
import AddLocationModal from './components/AddLocationModal';
import EditLocationModal from './components/EditLocationModal';
import SettleMentAccountModal from '../users/component/SettleMentAccountModal';
import SettleMentAccountPartnerModal from './components/SettleAccountPartner';
import SettingsLayout from './SettingsLayout';
import { generalSettingsList } from './SettingUtils';

type activeSettings =
	| 'business-profile'
	| 'verification'
	| 'team'
	| 'keys-webhook'
	| 'preference'
	| 'security';

const BankboxSettings = () => {
	const { profile } = useSelector((state: RootState) => state.settings);
	const [activeSettings, setActiveSettings] = useState<activeSettings | string>(
		'basic'
	);
	const [singleChild, setSingleChild] = useState<any>({});
	type showModalProp = {
		delete: boolean;
		add: boolean;
		edit: boolean;
		edit_settle: boolean;
	};
	const [refreshTable, setRefreshTable] = useState(false);
	const [showModal, setShowModal] = useState<showModalProp>({
		delete: false,
		add: false,
		edit: false,
		edit_settle: false,
	});
	const [formData, setFormData] = useState<BankboxMerchantProfile>({
		address: '',
		app_id: '',
		app_name: '',
		bank_collection_fee: 0,
		card_fee: 0,
		card_fee_cap: 0,
		card_fee_model: '',
		color_code: '',
		created_at: '',
		device_price: 0,
		email: '',
		fee_ref: null,
		id: 0,
		key_serial: '',
		logo_url: null,
		mandatory_fee: 0,
		region: '',
		status: 0,
		support_email: '',
		updated_at: '',
		virtual_account: 0,
		virtual_account_provider: '',
		webhook_secret: null,
		webhook_url: null,
		website: null,
	});
	const [showEODModal, setShowEODModal] = useState(false);
	function isSettings(wl: string) {
		if (activeSettings === wl) return true;
		else return false;
	}

	const [theme, toggleTheme] = useDarkMode(localStorage.getItem('theme'));
	const [logoChanged, setIsLogoChange] = useState(false);
	const [logo, setLogo] = useState<File | undefined>();

	const { loading, banks } = useSelector((state: RootState) => state.bankbox);
	const [canUpdate, setCanUpdate] = useState<boolean>(false);
	const dispatch = useDispatch<AppDispatch>();

	// useEffect(() => {
	// 	insertUrlParam(
	// 		'active-setting',
	// 		activeSettings,
	// 		`${capitalizeFirstLetter(
	// 			`${activeSettings}`
	// 		)} Bills Payment | Raven Atlas`,
	// 		true
	// 	);
	// }, [activeSettings]);

	useEffect(() => {
		const billie: activeSettings | null | string =
			searchParams.get('active-setting');
		billie !== null ? setActiveSettings(billie) : '';
	}, []);

	async function fetchProfile() {
		const resp = await dispatch(bankboxAPI.getProfile({}));

		if (resp.payload?.status === 'success') {
			// console.log(resp.payload.data.data);

			setFormData(resp.payload.data.data);
		}
	}

	useEffect(() => {
		fetchProfile();
		// dispatch(getSettingsConfig());
		if (banks?.length < 1) {
			dispatch(bankboxAPI.getBankboxBanks({}));
		}
		// getSettingsConfig
		const urlToUse = window.location.href?.split('#')[1];
		if (urlToUse) {
			setTimeout(() => {
				setActiveSettings(urlToUse);
			}, 1000);
		}
	}, []);

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...formData, [name]: value };
		setFormData(obj);
		if (!canUpdate) {
			setCanUpdate(true);
		}
	};

	const handleUpload = async (t: boolean) => {
		const uploadData = new FormData();

		uploadData.append('business_logo', logo as Blob);
		const resp = await dispatch(bankboxAPI.uploadLogo(uploadData));
		if (resp.payload.status === 'success') {
			if (t) {
				toast.success('Logo updated successfully');
			}

			if (canUpdate) {
				//  const data = await		dispatch(bankboxAPI.updateProfile(formData));
				//  console.log(data);
			}
			setCanUpdate(false);
			setIsLogoChange(false);
		}
	};

	const handleSubmit = async () => {
		if (logoChanged) {
			handleUpload(!canUpdate);
		} else {
			const obj = {
				...formData,
				color_code: cleanHex(formData.color_code),
				device_price: removeCommaAndNairaSign(formData.device_price) as any,
				mandatory_fee: removeCommaAndNairaSign(formData.mandatory_fee) as any,
				card_fee: removeCommaAndNairaSign(formData.card_fee) as any,
				card_fee_cap: removeCommaAndNairaSign(formData.card_fee_cap) as any,
			};
			// console.log(obj);

			dispatch(bankboxAPI.updateProfile(obj));
		}
	};

	//initialize scroll to location
	useScrollToLocation();

	const handleFinish = () => {
		setRefreshTable(!refreshTable);
		setShowModal((prev) => {
			return { ...prev, add: false, delete: false, edit: false };
		});
	};

	const [loadingDelete, setLoadingDelete] = useState(false);
	async function handleDeleteLocation() {
		setLoadingDelete(true);
		const resp = await dispatch(
			bankboxAPI.deleteLocation({ id: singleChild?.id })
		);

		if (resp.payload.status === 'success') {
			setSingleChild({});
			setLoadingDelete(false);
			handleFinish();
		} else {
			setLoadingDelete(false);
		}
	}

	const [selectedItem, setSelectedItem] = useState(generalSettingsList[0]);

	return (
		<>
			{/* setting header start */}

			{/* setting header end */}
			<SettingsLayout
				btnContent={
					selectedItem !== 'Pickup Stations' &&
					getActionNamesByPolicyName(
						profile?.rolePolicies?.policies,
						'settings'
					)?.includes('edit_settings') && (
						<>
							{formData?.app_name && (
								<RavenButton
									loading={loading}
									disabled={!canUpdate && !logoChanged}
									onClick={handleSubmit}
									className="non-mobile"
									color="deep-green-light"
								>
									Save Changes
								</RavenButton>
							)}
							<RavenButton
								loading={loading}
								disabled={!canUpdate && !logoChanged}
								onClick={handleSubmit}
								className="non-desktop"
								color="deep-green-light"
							>
								Save
							</RavenButton>
						</>
					)
				}
			>
				<div className="settings-general-wrap-box-wrapper">
					{/* left content start */}
					<div className="left-content grey-bg">
						{generalSettingsList?.map((chi, idx) => {
							if (
								!getActionNamesByPolicyName(
									profile?.rolePolicies?.policies,
									'settlement'
								)?.includes('view_settlement') &&
								chi === 'Settlement Account'
							) {
								return <React.Fragment key={idx}></React.Fragment>;
							}
							if (
								!getActionNamesByPolicyName(
									profile?.rolePolicies?.policies,
									'settings'
								)?.includes('generate_eod_report') &&
								chi === 'EOD'
							) {
								return <React.Fragment key={idx}></React.Fragment>;
							}
							return (
								<div
									onClick={() => {
										if (selectedItem !== chi) {
											setSelectedItem(chi);
										}
									}}
									className={`item-row ${
										selectedItem === chi ? 'item-row-active white-black-bg' : ''
									}`}
									key={idx}
								>
									<p className="name"> {chi}</p>
								</div>
							);
						})}
					</div>
					{/* left content end */}
					{/* right content start */}
					<div className="right-content">
						<>
							<BasicProfile
								loading={loading}
								logoFile={(e) => {
									setLogo(e);
								}}
								selectedItem={selectedItem}
								logoChanged={(e) => {
									setIsLogoChange(e);
								}}
								value={formData}
								onChange={handleChange}
								activeSettings={(e) => setActiveSettings(e as string)}
								onEOD={() => {
									setShowEODModal(true);
								}}
								singleChild={singleChild}
								setSingleChild={setSingleChild}
								refreshTable={refreshTable}
								setRefreshTable={setRefreshTable}
								setShowModal={setShowModal}
								showModal={showModal}
								setImageUrl={(p) => {
									setFormData((prev) => {
										return { ...prev, logo_url: p };
									});
								}}
							/>
						</>
					</div>
					{/* right content end */}
				</div>
				{/* <div className={`settings ${theme}`}>
					<div className="settings__sidebar">
						<div className="sidebar__selector">
							<a
								href="#basic"
								onClick={() => setActiveSettings('basic')}
								className={`selector__item ${isSettings('basic') && 'active'}`}
							>
								<p>Basic Details</p>
							</a>
							<a
								href="#settle"
								onClick={() => setActiveSettings('settle')}
								className={`selector__item ${isSettings('settle') && 'active'}`}
							>
								<p>Settlement Account</p>
							</a>
							<a
								href="#prices"
								onClick={() => setActiveSettings('prices')}
								className={`selector__item ${isSettings('prices') && 'active'}`}
							>
								<p>Price, Rates & Fees</p>
							</a>
							<a
								href="#operations"
								onClick={() => setActiveSettings('operations')}
								className={`selector__item ${
									isSettings('operations') && 'active'
								}`}
							>
								<p>Operations & Support</p>
							</a>
							<a
								href="#webhook"
								onClick={() => setActiveSettings('webhook')}
								className={`selector__item ${
									isSettings('webhook') && 'active'
								}`}
							>
								<p>Webhook & Keys</p>
							</a>

							<a
								href="#eod"
								onClick={() => setActiveSettings('eod')}
								className={`selector__item ${isSettings('eod') && 'active'}`}
							>
								<p>EOD</p>
							</a>

							<a
								href="#pickup"
								onClick={() => setActiveSettings('pickup')}
								className={`selector__item ${isSettings('pickup') && 'active'}`}
							>
								<p>Pickup Stations</p>
							</a>
						</div>

						<>
							<BasicProfile
								loading={loading}
								logoFile={(e) => {
									setLogo(e);
								}}
								logoChanged={(e) => {
									setIsLogoChange(e);
								}}
								value={formData}
								onChange={handleChange}
								activeSettings={(e) => setActiveSettings(e as string)}
								onEOD={() => {
									setShowEODModal(true);
								}}
								singleChild={singleChild}
								setSingleChild={setSingleChild}
								refreshTable={refreshTable}
								setRefreshTable={setRefreshTable}
								setShowModal={setShowModal}
								showModal={showModal}
								setImageUrl={(p) => {
									setFormData((prev) => {
										return { ...prev, logo_url: p };
									});
								}}
							/>
						</>
					</div>
				</div> */}
			</SettingsLayout>
			{/* modal start */}
			<EODExportModal
				visible={showEODModal}
				onClose={() => {
					setShowEODModal(false);
				}}
			/>
			{/* modal end */}
			<DeleteModal
				onDelete={handleDeleteLocation}
				loading={loadingDelete}
				deleteText={`Delete Location`}
				buttonColor={''}
				visible={showModal?.delete}
				onCancel={() => {
					setShowModal((prev) => {
						return { ...prev, delete: false };
					});
				}}
				title={`Remove Pickup location ( ${trimLongString(
					singleChild?.address,
					12
				)} )`}
				text={`Are you sure you want to remove this location from the list of all the locations that you have?.`}
			/>
			{/* modal end */}
			<AddLocationModal
				visible={showModal?.add}
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, add: false };
					});
				}}
				detail={singleChild}
				onFinish={handleFinish}
			/>
			<EditLocationModal
				visible={showModal?.edit}
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, edit: false };
					});
				}}
				onFinish={handleFinish}
				detail={singleChild}
			/>
			<SettleMentAccountPartnerModal
				onClose={() => {
					setShowModal((prev) => {
						return { ...prev, edit_settle: false };
					});
				}}
				visible={showModal?.edit_settle}
				value={formData}
			/>
		</>
	);
};

export default BankboxSettings;
