import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import mainAxios from '../utils/banbox_axios';
import { toast } from '@ravenpay/raven-bank-ui';
import { InviteTeamPayload, PaymentPreference, SettingsState } from './types';
import { logger } from '../services/logger';
// import { setProfile } from './wallet';

export const getSettingsConfig = createAsyncThunk(
	'/settings-config',
	async (payload, thunkAPI) => {
		try {
			const data = await mainAxios.get(`/atlas/pdon/bankbox_config`);
			// console.log(data);

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				// console.log(data);

				thunkAPI.dispatch(setConfig(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getTeams = createAsyncThunk(
	'/fetch-teams',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/web/merchant_team`);
			// logger.log(data, 'TIIMS');

			if (data?.data?.status === 'fail') {
				toast.error(data?.data.message, {
					position: 'top-right',
				});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setTeams(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getSupportInviteData = createAsyncThunk(
	'/fetch-teams',
	async (payload: { identifier: string | undefined | null }, thunkAPI) => {
		try {
			const data = await axios.get(
				`/web/get_support_invite?identifier=${payload.identifier}`
			);

			if (data?.data?.status === 'fail') {
				if (typeof data.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				// thunkAPI.dispatch(setTeams(data?.data?.data?.data));
				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getDirectors = createAsyncThunk(
	'/fetch-directors',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/web/directors`);

			// if (data?.data?.status === 'fail') {
			// 	toast.error(data?.data.message, {
			// 		position: 'top-right',
			// 	});
			// 	return thunkAPI.rejectWithValue(data);
			// }
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setDirectors(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getProfile = createAsyncThunk(
	'/support-profile',
	async (payload, thunkAPI) => {
		try {
			const data = await mainAxios.get(`atlas/pdon/get_logged_in_user`);
			// logger.log(data);
			// console.log(data?.data?.data?.data, "settings---------------");
			
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				thunkAPI.dispatch(setProfile(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const getRoles = createAsyncThunk(
	'/get-roles',
	async (payload, thunkAPI) => {
		try {
			const data = await axios.get(`/atlas/pdon/role`);
			// logger.log(data);
			// console.log(data, "settings");
			
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				console.log(data);
				
				// toast.success(data?.data.message, {
				// 	position: 'top-right',
				// });
				// thunkAPI.dispatch(setProfile(data?.data?.data?.data));
				return data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const inviteTeamMember = createAsyncThunk(
	'/create-support-profile',
	async (payload: InviteTeamPayload, thunkAPI) => {
		try {
			const data = await axios.post(`/web/create_support_invite`, payload);
			logger.log(data, 'team member');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data?.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const updatePaymentPreference = createAsyncThunk(
	'/update-preference',
	async (payload: PaymentPreference[], thunkAPI) => {
		logger.log(payload, 'payload');
		try {
			const data = await axios.post(`web/update_payment_preferences`, {
				payment_preferences: payload,
			});

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data?.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
export const updateWebhook = createAsyncThunk(
	'/update-preference',
	async (
		payload: {
			webhook_url: string;
			webhook_secret_key: string;
		},
		thunkAPI
	) => {
		logger.log(payload, 'payload');
		try {
			const data = await axios.post(`web/webhooks/update`, {
				webhook_url: payload.webhook_url,
				webhook_secret_key: payload.webhook_secret_key,
			});

			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data?.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
export const deleteTeamMember = createAsyncThunk(
	'/delete-team-member',
	async (payload: { email: string }, thunkAPI) => {
		try {
			const data = await axios.post(
				`web/delete_merchant_support_account`,
				payload
			);
			logger.log(data, 'team member');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data?.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
export const deleteTeamInvite = createAsyncThunk(
	'web/delete_support_invite',
	async (payload: { email: string }, thunkAPI) => {
		try {
			const data = await axios.post(`web/delete_support_invite`, payload);
			logger.log(data, 'team member');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data?.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);

export const changePassword = createAsyncThunk(
	'web/change_password',
	async (
		payload: {
			old_password: string;
			new_password: string;
			confirm_password: string;
		},
		thunkAPI
	) => {
		try {
			const data = await axios.post(`/web/change_password`, payload);
			logger.log(data, 'team member');
			if (data?.data?.status === 'fail') {
				if (typeof data?.data?.data === 'string') {
					toast.error(data?.data.data, {
						position: 'top-right',
					});
				} else
					toast.error(data?.data.message, {
						position: 'top-right',
					});
				return thunkAPI.rejectWithValue(data);
			}
			if (data?.data?.status === 'success') {
				toast.success(data?.data.data?.message, {
					position: 'top-right',
				});

				return data?.data;
			}
		} catch (error: any) {
			if (error.message === 'Network Error') {
				toast.error(error.message, {
					position: 'top-right',
				});
			}
			if (
				error.response.data.status === 'fail' &&
				error.response.status !== 401
			) {
				return thunkAPI.rejectWithValue(error);
			}
		}
	}
);
const initialState: SettingsState = {
	loading: false,
	loadingRoles: false,
	isAuth: false,
	config: {},
	profile: {},
	teams: [],
	directors: [],
	// initialize other state properties
};

export const settingSlice = createSlice({
	name: 'auth',
	initialState,

	reducers: {
		setConfig: (state, action) => {
			state.config = action.payload;
			state.isAuth = true;
		},
		setProfile: (state, action) => {
			state.profile = action.payload;
			state.isAuth = true;
		},
		setTeams: (state, action) => {
			state.teams = action.payload;
			state.isAuth = true;
		},
		setDirectors: (state, action) => {
			state.directors = action.payload;
			state.isAuth = true;
		},
	},

	extraReducers: (builder) => {
		// pending reducers
		builder.addCase(getSettingsConfig.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getRoles.pending, (state) => {
			state.loadingRoles = true;
		});
		builder.addCase(getTeams.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(inviteTeamMember.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(getDirectors.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(deleteTeamMember.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(deleteTeamInvite.pending, (state) => {
			state.loading = true;
		});
		builder.addCase(changePassword.pending, (state) => {
			state.loading = true;
		});

		// fulfilled reducers
		builder.addCase(getSettingsConfig.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getRoles.fulfilled, (state) => {
			state.loadingRoles = false;
		});
		builder.addCase(getTeams.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(inviteTeamMember.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(getDirectors.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(deleteTeamMember.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(deleteTeamInvite.fulfilled, (state) => {
			state.loading = false;
		});
		builder.addCase(changePassword.fulfilled, (state) => {
			state.loading = false;
		});

		// rejected reducers
		builder.addCase(getSettingsConfig.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getRoles.rejected, (state) => {
			state.loadingRoles = false;
		});
		builder.addCase(getTeams.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(inviteTeamMember.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(getDirectors.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(deleteTeamMember.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(deleteTeamInvite.rejected, (state) => {
			state.loading = false;
		});
		builder.addCase(changePassword.rejected, (state) => {
			state.loading = false;
		});
	},
});

// Action creators are generated for each case reducer function
export const { setConfig, setTeams, setDirectors, setProfile } =
	settingSlice.actions;

export default settingSlice.reducer;
